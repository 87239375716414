<script setup>
const leasing = useLeasingParams();
const props = defineProps({
  value: [Number, String],
  offerClient: String
})

const indicatorText = computed(() => {
    if(props.offerClient) {
        return  props.offerClient  === 'entrepreneur' ? 'netto' : 'brutto';
    } else {
        return leasing.refParams.value.client === 'entrepreneur' ? 'netto' : 'brutto';
    }
})
</script>

<template>
<span>
    {{indicatorText}}
</span>
</template>
